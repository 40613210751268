import type { JSX } from 'preact';
import { h } from 'preact';
import { isNonEmptyString } from '@wistia/type-guards';
import { CaretDownIcon } from '../../../shared/CaretDownIcon.jsx';
import { useFormState } from '../../context/FormStateContext.tsx';
import { CustomFieldOption } from './types.ts';

export const DROPDOWN_STYLES = `
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--input-background-color);
    border-radius: var(--input-border-radius);
    border: 1px solid var(--input-border-color);
    font-family: var(--input-font-family);
    font-size: var(--font-size-2);
    line-height: var(--spacing-5);
    outline: none;
    padding: var(--spacing-2);
    width: 100%;
    color: var(--input-text-color);
  }

  option {
    color: black;
  }

  select:focus {
    border-color: var(--button-border-color);
  }

  .select-caret-container  {
    pointer-events: none;
    position: absolute;
    right: var(--spacing-2);
    top: calc(50% + 1px);
    transform: translateY(-50%);
    width: var(--spacing-3);
  }
`;

type FormSelectProps = Omit<JSX.HTMLAttributes<HTMLSelectElement>, 'part' | 'style'> & {
  id: string;
  options: CustomFieldOption[];
  part: string;
  placeholder?: string;
  style?: JSX.CSSProperties;
};

export const Dropdown = (props: FormSelectProps): JSX.Element => {
  const { id, options, placeholder, ...attributesForSelect } = props;

  const {
    liveEventConfig: { customizations },
  } = useFormState();
  const { input_text_color } = customizations;

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <select id={id} name={id} {...attributesForSelect} part={`input ${props.part}`}>
        {isNonEmptyString(placeholder) && (
          <option value="" disabled selected>
            {placeholder}
          </option>
        )}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div class="select-caret-container">
        <CaretDownIcon color={input_text_color ?? 'black'}></CaretDownIcon>
      </div>
    </div>
  );
};
