import { isNotNil, Nilable } from '@wistia/type-guards';
import type { CustomField, FormFieldConfig } from './components/FormFields/types.ts';
import { LiveFormCustomizations } from './utilities/FormApi.ts';
import { FieldValidationOptions } from './validation/types.ts';

export type BaseFormFieldConfig = {
  custom: false;
  enabled: boolean;
  id?: string;
  input_type: string;
  label: string;
  placeholder?: string;
  required: boolean;
  slug: string;
  validation?: FieldValidationOptions;
};

export type LocalStorageRegistrationInfo = RegistrationData & {
  eventId: string;
  role: string;
};

export type LiveEventLifecycleStatus =
  | 'ended'
  | 'failed'
  | 'pending'
  | 'ready'
  | 'started'
  | 'vod_ready';

export type LocalStorageAnalyticEvent = {
  load: boolean;
  register: boolean;
};

export type LiveStreamLocalStorageAnalyticEvents = {
  event: LocalStorageAnalyticEvent;
  eventId: number;
};

export type RegistrationData = {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
};

export type FieldState = 'disabled' | 'enabled' | 'required';

export const isFieldState = (fieldState: Nilable<string>): fieldState is FieldState =>
  isNotNil(fieldState) && ['disabled', 'enabled', 'required'].includes(fieldState);

export type InlineOptions = {
  formConfig: {
    customFields: CustomField[] | null;
    standardFields: Partial<FormFieldConfig>[];
  };
  isRedirectToEventOnRegisterEnabled: boolean;
  liveFormCustomizations: Partial<LiveFormCustomizations>;
};

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};
