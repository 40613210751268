import { useCallback, useEffect, useState } from 'preact/hooks';
import { Nilable, Nullable } from '@wistia/type-guards';
import { FormApi, FormEmbedData } from '../utilities/FormApi.ts';
import { wlog } from '../../../utilities/wlog.js';
import { InlineOptions } from '../types.ts';
import { mergeCustomizations, mergeFormFields } from '../utilities/embedOptionsHelpers.ts';
import { FormFieldConfig } from '../components/FormFields/types.ts';

type UseFormDataReturn = {
  data: Nullable<FormEmbedData>;
  refresh: () => void;
};

export const useFormData = ({
  liveEventId,
  embedHost,
  inlineOptions,
}: {
  embedHost?: Nilable<string>;
  inlineOptions: InlineOptions;
  liveEventId: string;
}): UseFormDataReturn => {
  const [data, setData] = useState<FormEmbedData | null>(null);

  const refresh = useCallback(() => {
    const asyncFn = async () => {
      const formApi = new FormApi(embedHost);

      try {
        const result = await formApi.get(liveEventId);

        const {
          live_event: { customizations: customizationsFromServer },
          form: { fields: formFieldsFromServer, useFormAPIAsPrimary, simulateVmaSubmitFailure },
        } = result;

        const { liveFormCustomizations: inlineCustomizations, formConfig: inlineFormConfig } =
          inlineOptions;

        result.live_event.customizations = mergeCustomizations(
          customizationsFromServer,
          inlineCustomizations,
        );

        const customFields = formFieldsFromServer.filter((field) => field.custom);

        const standardFields = mergeFormFields(
          formFieldsFromServer.filter((field) => !field.custom),
          inlineFormConfig.standardFields as FormFieldConfig[],
        ) as FormFieldConfig[];

        setData({
          form: {
            customFields,
            standardFields,
            useFormAPIAsPrimary,
            simulateVmaSubmitFailure,
          },
          live_event: result.live_event,
        });
      } catch (error) {
        wlog.error('Error fetching form data', error);
      }
    };

    void asyncFn();
  }, [liveEventId]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return { data, refresh };
};
