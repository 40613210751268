import { h, JSX } from 'preact';
import { isNil } from '@wistia/type-guards';
import type { CustomField } from './types.ts';
import { useFormValidation } from '../../context/FormValidationContext.tsx';
import { CustomFieldValidationError } from './CustomFieldValidationError.tsx';
import { Dropdown } from './Dropdown.tsx';

interface CustomDropdownFormFieldProps {
  field: CustomField;
  id: string;
}

const inputTypeToValidClassName = (inputType: string): string =>
  inputType.trim().replace(/ /g, '_').replace(/-/g, '_').toLowerCase();

export const CustomDropdownFormField = ({
  field,
  id,
}: CustomDropdownFormFieldProps): JSX.Element | null => {
  const validationResult = useFormValidation();

  if (isNil(field.options)) {
    return null;
  }

  if (isNil(field.label)) {
    return null;
  }

  // Handle spaces in the label
  const className = inputTypeToValidClassName(field.input_type);

  return (
    <div class={`form-field field-${className}`} part={`field field-${className}`}>
      <label htmlFor={id} part="label">
        {field.label}
        {field.required ? (
          <span part="requiredness-indicator" class="requiredness-indicator">
            {' '}
            (Required)
          </span>
        ) : null}
      </label>
      <CustomFieldValidationError field={field} validationResult={validationResult} />
      <Dropdown id={id} options={field.options} part={className} placeholder={field.placeholder} />
    </div>
  );
};
