import { isEmptyArray, isEmptyRecord, isNil, isNotNil } from '@wistia/type-guards';
import { Field, FormFieldConfig } from '../components/FormFields/types.ts';
import { LiveFormCustomizations } from './FormApi.ts';

export const mergeFormFields = (
  formFieldsFromServer: Field[],
  inlineFormFields: FormFieldConfig[],
): Field[] => {
  if (isEmptyArray(inlineFormFields)) {
    return formFieldsFromServer;
  }

  return formFieldsFromServer.map((field) => {
    if (field.custom) {
      return field;
    }

    const inlineField = inlineFormFields.find(
      (fieldConfig: Partial<FormFieldConfig>) => fieldConfig.slug === field.slug,
    );

    if (isNil(inlineField)) {
      return field;
    }

    return {
      ...field,
      ...inlineField,
    } as Field;
  });
};

export const mergeCustomizations = (
  customizationsFromServer: LiveFormCustomizations,
  inlineCustomizations: Partial<LiveFormCustomizations>,
): LiveFormCustomizations => {
  if (isEmptyRecord(inlineCustomizations)) {
    return customizationsFromServer;
  }

  return {
    ...customizationsFromServer,
    ...Object.entries(inlineCustomizations).reduce<Partial<LiveFormCustomizations>>(
      (acc, [key, value]) => {
        if (isNotNil(value)) {
          acc[key] = value;
        }

        return acc;
      },
      {},
    ),
    // The value from the server for `has_powered_by_wistia_badge` should always be respected
    has_powered_by_wistia_badge: customizationsFromServer.has_powered_by_wistia_badge,
  };
};
