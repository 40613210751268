import { FormConfig, FormSubmissionData } from './FormApi.ts';
import { Field, FieldWithId } from '../components/FormFields/types.ts';
import { validate } from '../validation/index.ts';
import { FieldValidationResult } from '../validation/types.ts';

export type FormValidation = {
  isValid: boolean;
  results: FieldValidationResult[];
};

const hasId = (field: Field) => field.id;
const isEnabled = (field: Field) => field.enabled;
const validateField = (field: FieldWithId, data: FormSubmissionData) => {
  return validate(field, data);
};

// TODO:: We should probably add and remove this validation on the backend.
const maybeAddRequiredValidation = (field: FieldWithId): FieldWithId => {
  if (field.required) {
    return {
      ...field,
      validation: {
        isRequired: { required: field.required },
        ...field.validation,
      },
    };
  }

  return field;
};

export const validateFormFields = (form: FormConfig, data: FormSubmissionData): FormValidation => {
  const allFields = [...form.standardFields, ...form.customFields];
  const enabledFieldsWithId = allFields.filter(isEnabled).filter(hasId) as FieldWithId[];

  const results = enabledFieldsWithId
    .map(maybeAddRequiredValidation)
    .map((field) => validateField(field, data));

  const isValid = Object.values(results).every((value) => value.isValid);

  return {
    results,
    isValid,
  };
};
