import { isNonEmptyArray } from '@wistia/type-guards';
import { Field } from '../components/FormFields/types.ts';
import {
  FormSubmissionData,
  FormSubmissionPayload,
  FormFieldData,
  FormFieldLeadResponse,
} from './FormApi.ts';

const getIds = (formFields: Field[]): string[] => formFields.map(({ id }) => String(id));

const getCustomFieldValues = <T>(
  formData: FormSubmissionData,
  inScopeIds: string[],
  idKey: string,
): T[] => {
  return Object.entries(formData)
    .filter(([key]) => inScopeIds.includes(key))
    .map(
      ([key, value]) =>
        ({
          [idKey]: parseInt(key, 10),
          value,
        }) as T,
    );
};

export const getFormFieldData = (
  formFields: Field[],
  formData: FormSubmissionData,
): FormFieldData[] => getCustomFieldValues<FormFieldData>(formData, getIds(formFields), 'id');

export const getConvertedFormData = (
  formFields: Field[],
  formData: FormSubmissionData,
): FormSubmissionPayload => {
  const customFieldIds = getIds(formFields);

  const customResponses = getCustomFieldValues<FormFieldLeadResponse>(
    formData,
    customFieldIds,
    'form_field_id',
  );

  const payload: Record<string, object[] | string> = {
    ...Object.fromEntries(
      Object.entries(formData).filter(([key]) => !customFieldIds.includes(key)),
    ),
  };

  if (isNonEmptyArray(customResponses)) {
    payload.form_field_lead_responses = customResponses;
  }

  return payload as FormSubmissionPayload;
};
