import { Fragment, JSX, h } from 'preact';
import { isNil, Nullable } from '@wistia/type-guards';
import { FormValidation } from '../../utilities/validateFormFields.ts';
import type { Field } from './types.ts';

interface CustomFieldValidationErrorProps {
  field: Field;
  validationResult: Nullable<FormValidation>;
}

const isTrueOrNull = (value: boolean | null): boolean => isNil(value) || value;

export const CustomFieldValidationError = ({
  field,
  validationResult,
}: CustomFieldValidationErrorProps): JSX.Element | null => {
  const validation = validationResult?.results.find(({ id }) =>
    field.custom ? id === field.id : id === field.slug,
  );

  if (
    isNil(validationResult) ||
    validationResult.isValid ||
    isNil(validation) ||
    isTrueOrNull(validation.isValid)
  ) {
    return null;
  }

  return (
    <Fragment>
      {validation.messages.map((message) => (
        <p key={message} class="validation-error" part="validation-error">
          {message}
        </p>
      ))}
    </Fragment>
  );
};
