import type { JSX } from 'preact';
import { h } from 'preact';
import { isEmptyString, isNil, Nullable } from '@wistia/type-guards';
import { useEffect, useRef, useState } from 'preact/hooks';
import { FormValidationContext } from '../../context/FormValidationContext.tsx';
import type { FormFieldConfig } from '../FormFields/types.ts';
import { SluggedFormField } from '../FormFields/SluggedFormField.tsx';
import { CustomTextFormField } from '../FormFields/CustomTextFormField.tsx';
import { FormButton } from '../FormButton.tsx';
import { wlog } from '../../../../utilities/wlog.js';
import { FormApi, FormSubmissionData, LiveTrackerRegistration } from '../../utilities/FormApi.ts';
import { getFormFieldData, getConvertedFormData } from '../../utilities/formDataHelpers.ts';
import {
  eventUrlWithRegistrantQueryParams,
  openUrlInNewTab,
} from '../../utilities/eventHelpers.ts';
import { useFormState } from '../../context/FormStateContext.tsx';
import { setRegistrationInfo } from '../../utilities/localStorageHelpers.ts';
import { FormAnalyticsApi } from '../../analytics/FormAnalyticsApi.ts';
import { FormHeader } from './FormHeader.tsx';
import { countMetric } from '../../../../utilities/simpleMetrics.js';
import { FormValidation, validateFormFields } from '../../utilities/validateFormFields.ts';
import { CustomDropdownFormField } from '../FormFields/CustomDropdownFormField.tsx';

export const FORM_INPUT_TYPES = {
  TEXT: 'text',
  DROPDOWN: 'dropdown',
};

export const UNREGISTERED_FORM_STYLES = `
  form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: var(--spacing-6);
    column-gap: var(--spacing-4);
    font-size: var(--font-size-2);
    line-height: 1.5;
  }

  .first-and-last-name-container {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: wrap;
    gap: var(--spacing-4);
    justify-content: space-between;
    min-width: 0;
    width: 100%;
  }

  .footer {
    display: flex;
    justify-content: end;
    width: 100%;
  }
`;

export const UnregisteredFormContent = (): Nullable<JSX.Element> => {
  const {
    formConfig,
    liveEventId,
    liveEventConfig,
    setIsRegistered,
    analyticsContext,
    embedHost,
    onRegister,
    onRegistrationError,
    isRedirectToEventOnRegisterEnabled,
    isPreview,
  } = useFormState();
  const formRef = useRef<HTMLFormElement | null>(null);
  const [formValidationResult, setFormValidationResult] = useState<FormValidation | null>(null);

  const { lifecycle_status, ready_to_attend } = liveEventConfig;
  const isStartedOrReady = lifecycle_status === 'started' || lifecycle_status === 'ready';
  const isVodReady = lifecycle_status === 'vod_ready';

  const handleFormButtonClick = (event: h.JSX.TargetedMouseEvent<HTMLButtonElement>) => {
    // prevent default here so we do not get a default form submission
    event.preventDefault();

    if (isPreview) {
      return;
    }

    const formElem = formRef.current;

    if (isNil(formElem) || isNil(formConfig)) {
      return;
    }

    const formData = new FormData(formElem);
    const rawFormData = Object.fromEntries(formData.entries()) as FormSubmissionData;
    const convertedFormData = getConvertedFormData(formConfig.customFields, rawFormData);

    convertedFormData.live_event_id = liveEventId;

    const validationResult = validateFormFields(formConfig, rawFormData);
    setFormValidationResult(validationResult);

    if (isNil(validationResult) || !validationResult.isValid) {
      return;
    }

    void new FormApi(embedHost)
      .submit(convertedFormData)
      .then(({ registration_uid, success }) => {
        if (!success || isNil(registration_uid) || isEmptyString(registration_uid)) {
          onRegistrationError({
            name: 'RegistrationError',
            message: 'Registration failed',
          });

          return;
        }

        const formFieldData = getFormFieldData(formConfig.customFields, rawFormData);

        const registration = {
          id: registration_uid,
          email: convertedFormData.email,
          eventId: liveEventId,
          firstName: convertedFormData.first_name,
          lastName: convertedFormData.last_name,
          role: 'audience',
        };

        setRegistrationInfo(registration);
        setIsRegistered(true);
        onRegister({
          firstName: registration.firstName,
          lastName: registration.lastName,
          email: registration.email,
          id: registration.id,
        });

        const analyticsData: LiveTrackerRegistration = {
          ...convertedFormData,
          form_field_data: formFieldData,
        };
        void FormAnalyticsApi.trackFormSubmitted(liveEventId, analyticsContext, analyticsData);

        // the event is started or ready, and it's within 30 minutes of the start time if it isn't started OR the
        // event is over and the vod is ready
        const shouldSendToEvent = (ready_to_attend && isStartedOrReady) || isVodReady;

        if (isRedirectToEventOnRegisterEnabled && shouldSendToEvent) {
          openUrlInNewTab(
            eventUrlWithRegistrantQueryParams(liveEventConfig.event_url, registration_uid),
          );
        }

        countMetric('live_reg_form/submitted', 1, {
          liveEventId,
          registrationUid: registration_uid,
        });
      })
      .catch((error: unknown) => {
        onRegistrationError({
          name: 'RegistrationError',
          message: error as string,
        });
      });
  };

  const nonRequiredStandardFields: FormFieldConfig[] = formConfig.standardFields.filter(
    (field) =>
      field.enabled &&
      field.slug !== 'first_name' &&
      field.slug !== 'last_name' &&
      field.slug !== 'disclaimer',
  );

  const disclaimerFields: FormFieldConfig[] = formConfig.standardFields.filter(
    (field) => field.enabled && field.slug === 'disclaimer',
  );

  const { customFields } = formConfig;

  const firstNameFieldConfig = formConfig.standardFields.find(
    (field) => field.slug === 'first_name',
  ) as FormFieldConfig | null;
  const lastNameFieldConfig = formConfig.standardFields.find(
    (field) => field.slug === 'last_name',
  ) as FormFieldConfig | null;

  const hasFirstAndLastNameConfig = firstNameFieldConfig != null && lastNameFieldConfig != null;

  if (!hasFirstAndLastNameConfig) {
    wlog.warn('first_name or last_name field not present in form config');
  }

  let buttonText = 'Register';
  if (isVodReady) buttonText = 'Register & watch recording';
  if (ready_to_attend && isStartedOrReady) buttonText = 'Register & join';

  useEffect(() => {
    if (isPreview) return;
    void FormAnalyticsApi.trackFormLoaded(liveEventId, analyticsContext);
  }, []);

  return (
    <form ref={formRef} noValidate={true} part="form">
      <FormHeader />
      <FormValidationContext.Provider value={formValidationResult}>
        {hasFirstAndLastNameConfig && (
          <div class="first-and-last-name-container">
            <SluggedFormField field={firstNameFieldConfig} />
            <SluggedFormField field={lastNameFieldConfig} />
          </div>
        )}
        {/* Filter the disclaimer to be rendered lower */}
        {nonRequiredStandardFields.length > 0 &&
          nonRequiredStandardFields.map((field) => {
            return <SluggedFormField field={field} key={field.slug} />;
          })}
        {/* Custom Fields */}
        {customFields.length > 0 &&
          customFields.map((field) => {
            const id = field.id ?? field.uuid;
            // We currently only supprt text fields
            // in the future we may support others for custom fields
            if (field.input_type === FORM_INPUT_TYPES.TEXT) {
              return <CustomTextFormField field={field} key={id} id={id} />;
            }

            if (field.input_type === FORM_INPUT_TYPES.DROPDOWN) {
              return <CustomDropdownFormField field={field} key={id} id={id} />;
            }

            return null;
          })}
        {/* Diclaimer goes at bottom of stack */}
        {disclaimerFields.length > 0 &&
          disclaimerFields.map((field) => <SluggedFormField field={field} key={field.slug} />)}
      </FormValidationContext.Provider>
      <div class="footer">
        <FormButton
          onClick={handleFormButtonClick}
          text={buttonText}
          id="w-register"
          disabled={isPreview}
        />
      </div>
    </form>
  );
};
