import { isNil } from '@wistia/type-guards';
import { Validator } from './validator.ts';
import { Field } from '../components/FormFields/types.ts';
import { ValidationResult } from './types.ts';

export class IsLength extends Validator<string> {
  private readonly maxLength: number;

  private readonly minLength: number;

  public constructor(field: Field, value: string) {
    super(field, value);

    if (!field.custom || isNil(field.validation) || isNil(field.validation.isLength)) {
      throw new Error('Field must have isLength validation.');
    }

    this.maxLength = field.validation.isLength.maxLength;
    this.minLength = field.validation.isLength.minLength;
  }

  public run = (): ValidationResult => {
    if (!this.field.custom || isNil(this.field.validation) || isNil(this.field.validation.isLength))
      return { isValid: true };

    const args = this.field.validation.isLength;
    const isValid = this.validateLength(isNil(this.value) ? '' : this.value, args);
    const message = isValid ? undefined : this.message();

    return { isValid, message };
  };

  private readonly message = (): string => {
    if (this.minLength === this.maxLength) {
      return `Field must be exactly ${this.minLength} characters.`;
    }

    return `Field must be between ${this.minLength} and ${this.maxLength} characters.`;
  };

  private readonly validateLength = (
    value: string,
    { maxLength, minLength }: { maxLength: number; minLength: number },
  ): boolean => {
    return value.length >= minLength && value.length <= maxLength;
  };
}
