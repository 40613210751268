import { Fragment, JSX, h } from 'preact';
import { isNil, Nullable } from '@wistia/type-guards';
import { FormValidation } from '../../utilities/validateFormFields.ts';
import type { FormFieldConfig } from './types.ts';

interface SluggedFieldValidationErrorProps {
  field: FormFieldConfig;
  validationResult: Nullable<FormValidation>;
}

export const SluggedFieldValidationError = ({
  field,
  validationResult,
}: SluggedFieldValidationErrorProps): JSX.Element | null => {
  const validation = validationResult?.results.find(({ id }) => id === field.slug);

  if (isNil(validationResult) || validationResult.isValid || isNil(validation)) {
    return null;
  }

  return (
    <Fragment>
      {validation.messages.map((message) => (
        <p key={message} class="validation-error" part="validation-error">
          {message}
        </p>
      ))}
    </Fragment>
  );
};
