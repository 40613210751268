import { h, JSX } from 'preact';
import { isNil } from '@wistia/type-guards';
import { useFormValidation } from '../../context/FormValidationContext.tsx';
import { FormInput } from '../FormInput.tsx';
import type { Field } from './types.ts';
import { CustomFieldValidationError } from './CustomFieldValidationError.tsx';

const inputTypeToValidClassName = (inputType: string): string =>
  inputType.trim().replace(/ /g, '_').replace(/-/g, '_').toLowerCase();

export const CustomTextFormField = ({
  field,
  id,
}: {
  field: Field;
  id: string;
}): JSX.Element | null => {
  const validationResult = useFormValidation();

  if (isNil(field.label)) {
    return null;
  }

  // Handle spaces in the label
  const className = inputTypeToValidClassName(field.input_type);

  return (
    <div class={`form-field field-${className}`} part={`field field-${className}`}>
      <label htmlFor={id} part="label">
        {field.label}
        {field.required ? (
          <span part="requiredness-indicator" class="requiredness-indicator">
            {' '}
            (Required)
          </span>
        ) : null}
      </label>
      <CustomFieldValidationError field={field} validationResult={validationResult} />
      <FormInput
        id={id}
        part={className}
        name={id}
        placeholder={field.placeholder}
        required={field.required}
        aria-label={field.label}
        aria-required={field.required}
        type={field.input_type}
      />
    </div>
  );
};
