import { h, render } from 'preact';
import { isNil, isNonEmptyString, isNotNil, Nilable } from '@wistia/type-guards';
import { Form } from './Form.tsx';
import '../../utilities/interFontFace.js';
import '../media/modules/_visitor_key.js';
import { FormEmbedData } from './utilities/FormApi.ts';
import type { FieldState, InlineOptions, RegistrationData } from './types.ts';
import { isFieldState } from './types.ts';
import { DisclaimerFormFieldConfig } from './components/Disclaimer.tsx';
import { CustomField, FormFieldConfig } from './components/FormFields/types.ts';
import { Wistia } from '../../wistia_namespace.ts';

const REQUIRED_ATTRIBUTES = ['live-event-id'];

const OPTIONAL_PUBLIC_ATTRIBUTES = [
  'form-title',
  'form-date-enabled',
  'form-disclaimer',
  'form-time-enabled',
  'form-redirect-on-register-enabled',
  'country-field',
  'job-title-field',
  'company-field',
  'phone-field',
  'form-fields',
];

const OPTIONAL_INTERNAL_ATTRIBUTES = ['embed-host'];

type MODIFIABLE_FIELD_SLUG =
  | 'company'
  | 'country'
  | 'email'
  | 'first_name'
  | 'job_title'
  | 'last_name'
  | 'phone_number';

/*
 * The wistia-form web component. This is where we define the top-level API, and start the render tree
 * for the preact-based form. The entry point for that form is the Form.tsx component.
 * */
export class WistiaForm extends HTMLElement {
  public inlineOptions: InlineOptions = {
    formConfig: {
      standardFields: [],
      customFields: null,
    },
    liveFormCustomizations: {},
    isRedirectToEventOnRegisterEnabled: true,
  };

  #preactRoot: HTMLDivElement | null = null;

  public constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    if (Wistia._initializers) {
      Wistia._initializers.initVisitorKey?.();
    }
  }

  public static get observedAttributes(): string[] {
    return [...REQUIRED_ATTRIBUTES, ...OPTIONAL_PUBLIC_ATTRIBUTES, ...OPTIONAL_INTERNAL_ATTRIBUTES];
  }

  public set companyFieldState(state: FieldState) {
    this.#modifyFieldConfig('company', state);
    this.#renderForm();
  }

  public set countryFieldState(state: FieldState) {
    this.#modifyFieldConfig('country', state);
    this.#renderForm();
  }

  public get embedHost(): string | null | undefined {
    return this.getAttribute('embed-host');
  }

  public set embedHost(value: string) {
    this.setAttribute('embed-host', value);
    this.#renderForm();
  }

  public get formDateEnabled(): boolean {
    return (
      this.hasAttribute('form-date-enabled') ||
      (this.inlineOptions.liveFormCustomizations.date_enabled ?? false)
    );
  }

  public set formDateEnabled(value: boolean) {
    this.inlineOptions.liveFormCustomizations.date_enabled = value;
    this.#renderForm();
  }

  public get formDisclaimer(): string {
    return this.getAttribute('form-disclaimer') ?? '';
  }

  public set formDisclaimer(value: string) {
    this.#modifyDisclaimerField(value);
    this.#renderForm();
  }

  public set formFields(value: (CustomField & { inputType?: string })[]) {
    const newState: CustomField[] = [];
    value.forEach((field) => {
      // This may come in as inputType from VMA graphql
      // normalize it to input_type
      if (isNotNil(field.inputType)) {
        // eslint-disable-next-line no-param-reassign
        field.input_type = field.inputType;
      }

      if (
        isNil(field.label) ||
        isNil(field.enabled) ||
        isNil(field.required) ||
        isNil(field.input_type) ||
        // requires either an id or a uuid
        (isNil(field.id) && isNil(field.uuid))
      ) {
        return;
      }
      newState.push(field);
    });

    this.inlineOptions.formConfig.customFields = newState;
    this.#renderForm();
  }

  public get formRedirectEnabled(): boolean {
    return this.inlineOptions.isRedirectToEventOnRegisterEnabled;
  }

  public set formRedirectEnabled(value: boolean) {
    this.inlineOptions.isRedirectToEventOnRegisterEnabled = value;
    this.#renderForm();
  }

  public get formTimeEnabled(): boolean {
    return (
      this.hasAttribute('form-time-enabled') ||
      (this.inlineOptions.liveFormCustomizations.time_enabled ?? false)
    );
  }

  public set formTimeEnabled(value: boolean) {
    this.inlineOptions.liveFormCustomizations.time_enabled = value;
    this.#renderForm();
  }

  public get formTitle(): string {
    return this.getAttribute('form-title') ?? this.inlineOptions.liveFormCustomizations.title ?? '';
  }

  public set formTitle(value: string) {
    this.setAttribute('form-title', value);
    this.inlineOptions.liveFormCustomizations.title = value;
    this.#renderForm();
  }

  public get isPreview(): boolean {
    return this.getAttribute('is-preview') === 'true';
  }

  public get isWistiaPage(): boolean {
    return this.getAttribute('is-wistia-page') === 'true';
  }

  public set jobTitleFieldState(state: FieldState) {
    this.#modifyFieldConfig('job_title', state);
    this.#renderForm();
  }

  public get liveEventId(): string {
    return this.getAttribute('live-event-id') ?? '';
  }

  public set liveEventId(value: string) {
    this.setAttribute('live-event-id', value);
    this.#renderForm();
  }

  public set phoneNumberFieldState(state: FieldState) {
    this.#modifyFieldConfig('phone_number', state);
    this.#renderForm();
  }

  public refetchData(): void {
    this.dispatchEvent(
      new CustomEvent('refetch-data', {
        bubbles: true,
        cancelable: false,
        composed: true,
      }),
    );

    this.#renderForm();
  }

  protected attributeChangedCallback(
    name: string,
    oldValue: string | null,
    newValue: string | null,
  ): void {
    if (oldValue === newValue) {
      return;
    }

    switch (name) {
      case 'embed-host':
        this.embedHost = newValue ?? '';
        break;
      case 'form-title':
        this.formTitle = newValue ?? '';
        break;
      case 'form-disclaimer':
        this.formDisclaimer = newValue ?? '';
        break;
      case 'form-date-enabled':
        this.formDateEnabled = newValue === 'true';
        break;
      case 'form-time-enabled':
        this.formTimeEnabled = newValue === 'true';
        break;
      case 'form-redirect-on-register-enabled':
        this.formRedirectEnabled = newValue === 'true';
        break;
      case 'live-event-id':
        this.liveEventId = newValue ?? '';
        break;
      case 'country-field':
        this.countryFieldState = this.#toFieldState(newValue);
        break;
      case 'job-title-field':
        this.jobTitleFieldState = this.#toFieldState(newValue);
        break;
      case 'company-field':
        this.companyFieldState = this.#toFieldState(newValue);
        break;
      case 'phone-field':
        this.phoneNumberFieldState = this.#toFieldState(newValue);
        break;
      case 'form-fields':
        // Its not great to have to serialize and deserialize the form fields,
        // but for now its how this needs to work given the props API.
        // This also isn't a huge performance hit since this shouldnt be rerendering
        // comparitively often.
        this.formFields = JSON.parse(newValue ?? '[]') as CustomField[];
        break;
      default:
        break;
    }
  }

  protected connectedCallback(): void {
    this.#preactRoot = document.createElement('div');
    this.#preactRoot.style.width = '100%';
    this.#preactRoot.style.height = '100%';
    this.#preactRoot.style.overflowY = 'auto';

    this.#renderForm();

    this.shadowRoot?.append(this.#preactRoot);

    this.dispatchEvent(
      new CustomEvent('connected', {
        detail: {
          liveEventId: this.liveEventId,
        },
      }),
    );
  }

  #modifyDisclaimerField(disclaimerPreview: string): void {
    const disclaimerFieldConfig = this.inlineOptions.formConfig.standardFields.find(
      ({ slug }: FormFieldConfig) => slug === 'disclaimer',
    ) as Nilable<DisclaimerFormFieldConfig>;

    const disclaimerField = isNotNil(disclaimerFieldConfig);
    const disclaimerPresent = isNonEmptyString(disclaimerPreview);

    if (disclaimerField) {
      disclaimerFieldConfig.enabled = disclaimerPresent;
      disclaimerFieldConfig.required = disclaimerPresent;
      disclaimerFieldConfig.additional_field_data = disclaimerPreview;
    } else {
      this.inlineOptions.formConfig.standardFields.push({
        additional_field_data: disclaimerPreview,
        enabled: disclaimerPresent,
        input_type: 'checkbox',
        label: 'Disclaimer',
        required: disclaimerPresent,
        slug: 'disclaimer',
        custom: false,
      });
    }
  }

  #modifyFieldConfig(slug: MODIFIABLE_FIELD_SLUG, newState: FieldState): void {
    const { formConfig } = this.inlineOptions;
    const fieldConfig = formConfig.standardFields.find(
      (config: FormFieldConfig) => config.slug === slug,
    );

    if (isNil(fieldConfig)) {
      formConfig.standardFields.push({
        slug,
        enabled: newState !== 'disabled',
        required: newState === 'required',
      });

      return;
    }

    fieldConfig.enabled = newState !== 'disabled';
    fieldConfig.required = newState === 'required';
  }

  #onRegister(data: RegistrationData) {
    const event = new CustomEvent('register', {
      detail: data,
    });

    this.dispatchEvent(event);
  }

  #onRegistrationError(error: Error) {
    const event = new CustomEvent('registration-error', {
      detail: error,
    });

    this.dispatchEvent(event);
  }

  #renderForm(): void {
    if (!this.#preactRoot) {
      return;
    }

    render(
      <Form
        inlineOptions={this.inlineOptions}
        liveEventId={this.liveEventId}
        embedHost={this.embedHost}
        onHasData={(data: FormEmbedData) => {
          this.inlineOptions.liveFormCustomizations = data.live_event.customizations;
          this.inlineOptions.formConfig.standardFields = data.form.standardFields;
          this.inlineOptions.formConfig.customFields = data.form.customFields;
        }}
        onRegister={(data: RegistrationData) => this.#onRegister(data)}
        onRegistrationError={(error: Error) => this.#onRegistrationError(error)}
        isWistiaPage={this.isWistiaPage}
        isPreview={this.isPreview}
        onInitialRender={() => {
          this.dispatchEvent(new CustomEvent('initial-render'));
        }}
        customFields={this.inlineOptions.formConfig.customFields}
      />,
      this.#preactRoot,
    );
  }

  #toFieldState(fieldStateAsString: Nilable<string>): FieldState {
    if (isFieldState(fieldStateAsString)) {
      return fieldStateAsString;
    }

    return 'disabled';
  }
}

if (customElements.get('wistia-form') === undefined) {
  customElements.define('wistia-form', WistiaForm);
}
