import { Field } from '../components/FormFields/types.ts';
import { ValidationResult, ValidatorInterface } from './types.ts';

export abstract class Validator<T> implements ValidatorInterface<T> {
  public field: Field;

  public value: T;

  public constructor(field: Field, value: T) {
    this.field = field;
    this.value = value;
  }

  public abstract run(): ValidationResult;
}
