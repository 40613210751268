import { isNil } from '@wistia/type-guards';
import { Field } from '../components/FormFields/types.ts';
import { IsLength } from './isLength.ts';
import { IsRequired } from './isRequired.ts';
import { ValidatorInterface } from './types.ts';

type ValidationConstructor = new (field: Field, value: unknown) => ValidatorInterface<unknown>;

const validatorRegistry: Record<string, ValidationConstructor> = {
  isLength: IsLength,
  isRequired: IsRequired,
};

export class NoValidatorFoundError extends Error {
  public constructor(name: string) {
    super(`No validator found for ${name}.`);
  }
}

export const validatorByName = (name: string): ValidationConstructor => {
  if (isNil(validatorRegistry[name])) {
    throw new NoValidatorFoundError(name);
  }

  return validatorRegistry[name];
};
