import { h, JSX } from 'preact';
import { useEffect } from 'preact/hooks';
import { isEmptyRecord, isNil, isNotNil, Nilable, Nullable } from '@wistia/type-guards';
import { useFormData } from './hooks/useFormData.ts';
import { FormStateProvider } from './context/FormStateContext.tsx';
import { FormStateRouter } from './components/FormStates/FormStateRouter.tsx';
import { getRegistrationByEventId } from './utilities/localStorageHelpers.ts';
import { FormEmbedData } from './utilities/FormApi.ts';
import { countMetric } from '../../utilities/simpleMetrics.js';
import type { InlineOptions, RegistrationData } from './types.ts';
import { FormStyles } from './components/FormStyles.tsx';
import type { CustomField } from './components/FormFields/types.ts';

type FormProps = {
  customFields?: Nilable<CustomField[]>;
  embedHost?: Nilable<string>;
  inlineOptions: InlineOptions;
  isPreview: boolean;
  isWistiaPage?: boolean;
  liveEventId: string;
  onHasData: (data: FormEmbedData) => void;
  onInitialRender: () => void;
  onRegister: (data: RegistrationData) => void;
  onRegistrationError: (error: Error) => void;
};

/*
 * The top-level of the Preact tree for the wistia-form web component.
 * */
export const Form = ({
  liveEventId,
  embedHost,
  onHasData,
  inlineOptions,
  onRegister,
  onRegistrationError,
  isPreview,
  onInitialRender,
  isWistiaPage = false,
  customFields,
}: FormProps): Nullable<JSX.Element> => {
  const { isRedirectToEventOnRegisterEnabled } = inlineOptions;
  const { data, refresh } = useFormData({ liveEventId, embedHost, inlineOptions });
  const { form: formConfig, live_event: liveEventConfig } = data ?? {};

  useEffect(() => {
    countMetric('live_reg_form/rendered', 1, { liveEventId, inlineOptions, embedHost });
    onInitialRender();
  }, []);

  useEffect(() => {
    if (isNotNil(data) && !isEmptyRecord(data)) {
      onHasData(data);
    }
  }, [onHasData, data]);

  useEffect(() => {
    const refetchData = () => {
      refresh();
    };

    window.addEventListener('refetch-data', refetchData);

    return () => {
      window.removeEventListener('refetch-data', refetchData);
    };
  }, []);

  const isRegistered = isNotNil(getRegistrationByEventId(liveEventId));

  if (isNil(data) || isEmptyRecord(data) || isNil(formConfig) || isNil(liveEventConfig)) {
    return null;
  }

  formConfig.customFields = isNotNil(customFields) ? customFields : data.form.customFields;

  // Original fields with 'slug'
  return (
    <FormStateProvider
      initialState={{
        embedHost,
        isRegistered,
        liveEventId,
        formConfig,
        liveEventConfig,
        isWistiaPage,
        isRedirectToEventOnRegisterEnabled,
        isPreview,
      }}
      refreshData={refresh}
      onRegister={onRegister}
      onRegistrationError={onRegistrationError}
    >
      <FormStyles data={data} />
      <FormStateRouter />
    </FormStateProvider>
  );
};
